<template>
    <div>
        <form class="page" @submit.prevent>
            <h2>{{ $t('ui.common.logIn') }}</h2>
            <span class="sub-header">{{ $t(`ui.common.${pinOrPassword}.welcomeBack`, { brandName: $t('project.brand') }) }}</span>
            <PhoneNumber :formName="formNameId" :v="$v.form.username" :value="form.username" @value="form.username = $event" />
            <Password :formName="formNameId" :v="$v.form.password" :value="form.password" @value="form.password = $event" />
            <renderer v-if="error" class="login-error" :input="error" />
            <Checkbox v-if="!isPresto && isSmartPhone" id="keepLoggedIn" :checked="keepLoggedIn" @onCheck="onKeepLoggedIn">
                <span>{{ $t('ui.common.user.keepLoggedIn') }}</span>
            </Checkbox>

            <TurnstileWidget :name="$options.name" />

            <input
                type="submit"
                :aria-placeholder="$t('ui.common.logIn')"
                @click="login()"
                data-test-id="logInButton"
                class="button button-submit button-full"
                :value="$t('ui.common.logIn')"
                :disabled="!token || isSubmitDisabled"
            />
            <router-link :to="{ name: 'Reset Password', params: { phoneNumber: form.username || null } }" class="info underline text-mid">
                {{ $t(`ui.common.${pinOrPassword}.forgotPassword`) }}
            </router-link>
        </form>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { required, minLength, numeric } from 'vuelidate/lib/validators';
import { helper, android, deviceType, getter as coreGetter, mutation as coreMutation } from '@agi.packages/core';
import { auth, getter as platformGetter } from '@agi.packages/platform';

import Password from '@/components/Password.vue';
import Checkbox from '@/components/Checkbox.vue';
import PhoneNumber from '@/components/PhoneNumber.vue';
import { getter } from '@/store/modules/translations';

import SEOMixin from '@/components/Pages/SEO.mixin';
import PageMixin from '@/components/Pages/Page.mixin';
import AuthenticationMixin from '../mixins/Authentication.mixin';

export default {
    name: 'LoginPage',
    components: { Password, PhoneNumber, Checkbox },
    mixins: [PageMixin, AuthenticationMixin, SEOMixin],
    data() {
        return {
            form: {
                username: null,
                password: null,
            },
            formNameId: 'login-form',
            keepLoggedIn: false,
            isPresto: deviceType.isPresto(),
            isSmartPhone: deviceType.isSmartPhone(),
        };
    },
    validations() {
        const isPin = this.brandPreference.pin;
        return {
            form: {
                username: {
                    required,
                },
                password: {
                    required,
                    minLength: minLength(4),
                    ...(isPin && { numeric }),
                },
            },
        };
    },
    computed: {
        ...mapState({
            authError: (state) => state.platform.auth.error,
            phonePrefix: (state) => state.platform.settings?.brandIdentity?.phoneCountryCode,
            keepLoggedInEnabled: () => false,
            storedPhoneNumber: (state) => state.platform.auth.phoneNumber,
            turnstile(state) {
                return state.ui.turnstile.includes(this.$options.name);
            },
        }),
        ...mapGetters({
            pinOrPassword: getter.PIN_OR_PASSWORD,
            token: auth.getter.SECURED_TOKEN,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            turnstileError: auth.getter.GET_TURNSTILE_ERROR,
            country: platformGetter.GET_COUNTRY,
        }),
        inProgress() {
            return this.$store.getters[coreGetter.IS_LOADING](auth.action.LOGIN);
        },
        isSubmitDisabled() {
            return (!this.isPresto && this.$v.$invalid) || this.inProgress;
        },
        error() {
            return this.turnstileError || this.authError;
        },
    },
    methods: {
        ...mapMutations({
            setError: auth.mutation.SET_ERROR,
        }),
        settleLogin(start = true) {
            if (!this.turnstile) {
                this.login();
                return;
            }
            const { START_LOAD, END_LOAD } = coreMutation;
            this.$store.commit(start ? START_LOAD : END_LOAD, auth.action.LOGIN, { root: true });
            if (!start) {
                this.setError(this.$t('errors.ACCESS_DENIED'));
            }
        },
        onKeepLoggedIn(value) {
            this.keepLoggedIn = value;
        },
        login() {
            if (this.isPresto && this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            const credentials = {
                username: this.form.username,
                password: this.form.password,
                country: this.country,
                rememberMe: !this.isPresto && this.isSmartPhone && this.keepLoggedIn,
            };
            this.$store.dispatch(auth.action.LOGIN, credentials).finally(() => {
                this.form.password = null;
                this.$v.form.password.$reset();
            });
        },
    },
    created() {
        this.$store.dispatch(auth.action.RESET_ERROR);
        const phoneNumber = android.state.loginPhone || this.storedPhoneNumber;
        this.form.username = helper.removeCountryCode(phoneNumber, this.phonePrefix);
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch(auth.action.RESET_ERROR);
        this.$store.commit(auth.mutation.SET_PHONE_NUMBER, {
            phoneNumber: this.form.username,
            phonePrefix: this.phonePrefix,
        });
        next();
    },
};
</script>

<style lang="scss" scoped>
.login-error {
    color: $login-error-color;
    background-color: $login-error-background-color;
    border: $login-error-border-width solid $login-error-border-color;
    padding: 12px;
    font-size: 14px;
    margin: 15px 0;
    border-radius: $login-error-border-radius;
}
</style>
