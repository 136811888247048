export const fillGamesGapWithFakeGames = (games, gamesLimit) => {
    const newGames = [...games];
    for (let i = newGames.length; i < gamesLimit; i += 1) {
        newGames.push({
            id: `fake-${i}`,
            isFake: true,
            attributes: {
                name: '',
            },
        });
    }
    return newGames;
};
