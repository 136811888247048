<template>
    <LoginPage class="login-wrapper" />
</template>

<script>
import LoginPage from '../Pages/LoginPage';
export default {
    name: 'Login',
    components: { LoginPage },
};
</script>

<style scoped lang="scss">
.login-wrapper {
    margin-top: 20px;

    ::v-deep .page {
        padding: 0;
    }
}
</style>
