<template>
    <div>
        <form v-if="isMounted" class="login-form" @submit.prevent>
            <PhoneNumber
                :formName="formNameId"
                :v="$v.form.username"
                :value="form.username"
                @value="form.username = $event"
                v-bind="{
                    ...(phoneNumberValidation && {
                        placeholder: phoneNumberValidation.placeholder,
                        errorMessages: { phoneNumberFormat: $t(phoneNumberValidation.errorMessageKey) },
                    }),
                }"
            />
            <Password
                :formName="formNameId"
                :v="$v.form.password"
                :value="form.password"
                name="password-input"
                autocomplete="new-password"
                @value="form.password = $event"
            />
            <renderer v-if="error" class="login-error" :input="error" />
            <Checkbox v-if="!isPresto && isSmartPhone" id="keepLoggedIn" :checked="keepLoggedIn" @onCheck="onKeepLoggedIn">
                <span>{{ $t('ui.common.user.keepLoggedIn') }}</span>
            </Checkbox>

            <TurnstileWidget :name="$options.name" />

            <input
                type="submit"
                :aria-placeholder="$t('ui.common.logIn')"
                @click="login()"
                data-test-id="logInButton"
                class="button button-submit button-full"
                :value="$t('ui.common.logIn')"
                :disabled="isSubmitDisabled"
            />
            <router-link
                :to="{ name: 'Reset Password', params: { phoneNumber: form.username || null } }"
                class="info underline text-mid bold"
            >
                {{ $t(`ui.common.${pinOrPassword}.forgotPassword`) }}
            </router-link>
            <span class="join-now-text">
                {{ $t('ui.common.user.joinNowText') }}
                <router-link :to="{ name: 'JoinNow' }" class="bold underline" @click.native="trackLinkClick">
                    {{ $t('ui.common.user.joinNow') }}
                </router-link>
            </span>
        </form>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { required, minLength, numeric } from 'vuelidate/lib/validators';
import { helper, android, deviceType, getter as coreGetter, mutation as coreMutation } from '@agi.packages/core';
import { auth, getter as platformGetter } from '@agi.packages/platform';
import { getter } from '@/store/modules/translations';

import Password from '@/components/Password.vue';
import Checkbox from '@/components/Checkbox.vue';
import PhoneNumber from '@/components/PhoneNumber.vue';

export default {
    name: 'LoginForm',
    components: { Password, PhoneNumber, Checkbox },
    data() {
        return {
            isMounted: false,
            form: {
                username: null,
                password: null,
            },
            formNameId: 'login-form',
            keepLoggedIn: false,
            isPresto: deviceType.isPresto(),
            isSmartPhone: deviceType.isSmartPhone(),
        };
    },
    validations() {
        const isPin = this.brandPreference.pin;
        return {
            form: {
                username: {
                    required,
                    ...(this.phoneNumberValidation && { phoneNumberFormat: this.phoneNumberValidation.validator }),
                },
                password: {
                    required,
                    minLength: minLength(4),
                    ...(isPin && { numeric }),
                },
            },
        };
    },
    computed: {
        ...mapState({
            authError: (state) => state.platform.auth.error,
            phonePrefix: (state) => state.platform.settings?.brandIdentity?.phoneCountryCode,
            keepLoggedInEnabled: () => false,
            storedPhoneNumber: (state) => state.platform.auth.phoneNumber,
            turnstile(state) {
                return state.ui.turnstile.includes(this.$options.name);
            },
        }),
        ...mapGetters({
            pinOrPassword: getter.PIN_OR_PASSWORD,
            token: auth.getter.SECURED_TOKEN,
            country: platformGetter.GET_COUNTRY,
            turnstileError: auth.getter.GET_TURNSTILE_ERROR,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            phoneNumberValidation: platformGetter.GET_PHONE_NUMBER_VALIDATION,
        }),
        inProgress() {
            return this.$store.getters[coreGetter.IS_LOADING](auth.action.LOGIN);
        },
        isSubmitDisabled() {
            return !this.token || (!this.isPresto && this.$v.$invalid) || this.inProgress;
        },
        error() {
            return this.turnstileError || this.authError;
        },
    },
    methods: {
        ...mapMutations({
            setError: auth.mutation.SET_ERROR,
        }),
        trackLinkClick() {
            this.$gtm.query({
                event: 'join_now_click',
                click_element: 'link',
            });
        },
        settleLogin(start = true) {
            if (!this.turnstile) {
                this.login();
                return;
            }
            const { START_LOAD, END_LOAD } = coreMutation;
            this.$store.commit(start ? START_LOAD : END_LOAD, auth.action.LOGIN, { root: true });
            if (!start) {
                this.setError(this.$t('errors.ACCESS_DENIED'));
            }
        },
        onKeepLoggedIn(value) {
            this.keepLoggedIn = value;
        },
        login() {
            if (this.isPresto && this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            const credentials = {
                username: this.form.username,
                password: this.form.password,
                country: this.country,
                rememberMe: !this.isPresto && this.isSmartPhone && this.keepLoggedIn,
            };
            this.$store
                .dispatch(auth.action.LOGIN, credentials)
                .then(() => {
                    this.$emit('success');
                })
                .finally(() => {
                    this.form.password = null;
                    this.$v.form.password.$reset();
                });
        },
    },
    mounted() {
        this.isMounted = true;
        if (this.phoneNumberValidation && this.form.username) this.$v.form.username.$touch();
    },
    created() {
        this.$store.dispatch(auth.action.RESET_ERROR);
        const phoneNumber = android.state.loginPhone || this.storedPhoneNumber;
        this.form.username = helper.removeCountryCode(phoneNumber, this.phonePrefix);
    },
    beforeDestroy() {
        this.$store.dispatch(auth.action.RESET_ERROR);
        this.$store.commit(auth.mutation.SET_PHONE_NUMBER, {
            phoneNumber: this.form.username,
            phonePrefix: this.phonePrefix,
        });
    },
};
</script>

<style lang="scss" scoped>
.login {
    &-form {
        display: flex;
        flex-flow: column nowrap;
        .join-now-text {
            @extend %small-details-font-400;
            text-align: center;
        }
        .info {
            margin: 16px 0 8px 0;
        }
    }
    &-error {
        color: $login-error-color;
        background-color: $login-error-background-color;
        border: $login-error-border-width solid $login-error-border-color;
        padding: 12px;
        font-size: 14px;
        margin-bottom: 16px;
        border-radius: $login-error-border-radius;
    }
}
</style>
