import { sortType } from '@/js/casino-const';
import qs from 'qs';
import { getJurisdictionHostname } from '@/modules/core';
import { getObjectField } from '@/modules/core/utils/helper';
import { flatDataAttributes } from '@/modules/core/utils/strapi/flatDataAttributes';

export const unique = (array) => {
    return array.filter((el, index, arr) => index === arr.indexOf(el));
};

export const getLocalTypes = (actions, regex) => {
    // rename to `getLocalNames` BP-16141
    return Object.keys(actions).reduce((types, actionName) => {
        return { ...types, [actionName]: actions[actionName].split(regex || '/').pop() };
    }, {});
};

export const getKeyForGamesInCategory = ({ filterProps, start, limit, sortBy }) =>
    `category.${start}.${limit}.${sortBy ?? sortType.POPULAR}.${JSON.stringify(filterProps)}`;

export const buildStrapiQuery = (query) => {
    return qs.stringify(query, {
        encodeValuesOnly: true, // prettify URL
    });
};
export const strapiDefaultQueryParamsCasinoGame = {
    fields: ['name', 'providerThumbnails', 'importedIDs', 'provider', 'providerGameId', 'slug'],
    populate: ['squareImage', 'ribbon', 'ribbon.name'],
};
export const strapiDefaultQueryParamsCasinoBrand = {
    fields: [],
    populate: ['logo', 'name'],
};
export const strapiDefaultQueryParamsCasinoCategory = {
    fields: ['id', 'iconId', 'isHiddenCategory', 'globalPosition'],
    populate: ['name', 'position.position', 'position.jurisdiction.hostname'],
};

export const buildCasinoGamesStrapiQuery = ({ includeDrafts, sort, start, limit, filters, locale, filterFavourites }) => {
    return buildStrapiQuery({
        publicationState: includeDrafts ? 'preview' : 'live',
        sort,
        locale,
        pagination: { start: start, limit: limit },
        filterFavourites,
        ...strapiDefaultQueryParamsCasinoGame,
        filters: {
            ...filters,
            jurisdictions: {
                hostname: {
                    $eq: getJurisdictionHostname(),
                },
            },
        },
    });
};
export const buildCasinoGameStrapiQuery = ({ includeDrafts, gameSlug }) => {
    return buildStrapiQuery({
        publicationState: includeDrafts ? 'preview' : 'live',
        ...strapiDefaultQueryParamsCasinoGame,
        filters: {
            slug: gameSlug,
            jurisdictions: {
                hostname: {
                    $eq: getJurisdictionHostname(),
                },
            },
        },
    });
};
export const buildCasinoCategoriesStrapiQuery = ({ includeDrafts }) => {
    return buildStrapiQuery({
        publicationState: includeDrafts ? 'preview' : 'live',
        pagination: { start: 0, limit: 100 },
        ...strapiDefaultQueryParamsCasinoCategory,
        filters: {
            isHiddenCategory: false,
            jurisdictions: {
                hostname: {
                    $eq: getJurisdictionHostname(),
                },
            },
        },
    });
};
export const buildCasinoCategoryStrapiQuery = ({ includeDrafts }) => {
    return buildStrapiQuery({
        ...strapiDefaultQueryParamsCasinoCategory,
        filters: {
            jurisdictions: {
                hostname: {
                    $eq: getJurisdictionHostname(),
                },
            },
            ...(includeDrafts ? {} : { publishedAt: { $notNull: true } }),
        },
    });
};
export const buildCasinoGameBrandsStrapiQuery = ({ includeDrafts, locale }) => {
    return buildStrapiQuery({
        publicationState: includeDrafts ? 'preview' : 'live',
        sort: 'position:asc',
        pagination: { start: 0, limit: 100 },
        ...strapiDefaultQueryParamsCasinoBrand,
        locale,
        filters: {
            ...(includeDrafts ? {} : { publishedAt: { $notNull: true } }),
            jurisdictions: {
                hostname: {
                    $eq: getJurisdictionHostname(),
                },
            },
            // logo: {
            //     $notNull: true, // this filter won't work until we upgrade Strapi to >= 4.23.0 https://github.com/strapi/strapi/issues/12225
            // },
        },
    });
};
export const buildCasinoGameBrandStrapiQuery = ({ includeDrafts, locale, brandId }) => {
    return buildStrapiQuery({
        fields: ['name'],
        populate: ['logo'],
        locale,
        filters: {
            ...(includeDrafts ? {} : { publishedAt: { $notNull: true } }),
            jurisdictions: {
                hostname: {
                    $eq: getJurisdictionHostname(),
                },
            },
            id: brandId,
        },
    });
};

export const sortCasinoGamesForFavourites = ({ sortBy, games, filterProps }) => {
    return [...games].sort((a, b) => {
        const userFavsInOld = [...filterProps.favourites.old];
        const userFavsInStrapi = [...filterProps.favourites.strapi];
        if (sortBy === sortType.FAVOURITES_ADDED_DESC) {
            userFavsInOld.reverse();
            userFavsInStrapi.reverse();
        }

        const userFavsInOldCount = userFavsInOld.length;
        const userFavsInStrapiCount = userFavsInStrapi.length;
        const importedIDsA = a.importedIDs;
        const importedIDsB = b.importedIDs;

        const indexAInOldSystem = importedIDsA ? userFavsInOld.findIndex((oldId) => importedIDsA.some((id) => id === `${oldId}`)) : -1;
        const indexAInStrapi = userFavsInStrapi.indexOf(a.id);
        const indexBInOldSystem = importedIDsB ? userFavsInOld.findIndex((oldId) => importedIDsB.some((id) => id === `${oldId}`)) : -1;
        const indexBInStrapi = userFavsInStrapi.indexOf(b.id);

        if (sortBy === sortType.FAVOURITES_ADDED_ASC) {
            const indexA = indexAInOldSystem !== -1 ? indexAInOldSystem : indexAInStrapi + userFavsInOldCount;
            const indexB = indexBInOldSystem !== -1 ? indexBInOldSystem : indexBInStrapi + userFavsInOldCount;

            return indexA - indexB;
        } else {
            const indexA = indexAInOldSystem !== -1 ? indexAInOldSystem + userFavsInStrapiCount : indexAInStrapi;
            const indexB = indexBInOldSystem !== -1 ? indexBInOldSystem + userFavsInStrapiCount : indexBInStrapi;

            return indexA - indexB;
        }
    });
};

export const getSortedCasinoCategories = (categories) => {
    const hostname = getJurisdictionHostname();
    return [...categories].sort(function (x, y) {
        const findFn = (p) => p.jurisdiction && p.jurisdiction.data.hostname === hostname;
        const overridenX = getObjectField(x, 'position', []).find(findFn);
        const overridenY = getObjectField(y, 'position', []).find(findFn);
        const positionX = overridenX ? overridenX.position : getObjectField(x, 'globalPosition', 0);
        const positionY = overridenY ? overridenY.position : getObjectField(y, 'globalPosition', 0);
        return positionX - positionY;
    });
};

export const buildCasinoGamesFavouritesFilter = ({ filterProps, sortBy, start, limit }) => {
    if (!filterProps.favourites) {
        return undefined;
    }
    let favouritesOldIdsToFilter = [-1, ...filterProps.favourites.old];
    let favouritesNewIdsToFilter = [-1, ...filterProps.favourites.strapi]; // we pass -1 to never have empty array

    if (sortBy === sortType.FAVOURITES_ADDED_ASC || sortBy === sortType.FAVOURITES_ADDED_DESC) {
        // If we order by order of the favs array, then we paginate resources on frontend side.
        // Then we slice the favsIDs to return only what is of interest to us.
        // We also adjust pagination params to always return first page
        const end = start + limit;
        const oldFiltersLength = favouritesOldIdsToFilter.length;
        const newFiltersLength = favouritesNewIdsToFilter.length;
        if (sortBy === sortType.FAVOURITES_ADDED_ASC) {
            favouritesOldIdsToFilter = favouritesOldIdsToFilter.slice(start, end);
            favouritesNewIdsToFilter = favouritesNewIdsToFilter.slice(
                Math.max(0, start - oldFiltersLength),
                Math.max(end - oldFiltersLength, 0)
            );
        } else if (sortBy === sortType.FAVOURITES_ADDED_DESC) {
            favouritesNewIdsToFilter.reverse();
            favouritesOldIdsToFilter.reverse();
            favouritesNewIdsToFilter = favouritesNewIdsToFilter.slice(start, end);
            favouritesOldIdsToFilter = favouritesOldIdsToFilter.slice(
                Math.max(0, start - newFiltersLength),
                Math.max(end - newFiltersLength, 0)
            );
        }
    }

    return {
        new: favouritesNewIdsToFilter.sort(),
        old: favouritesOldIdsToFilter.sort(),
    };
};

export const buildCasinoGamesFilters = ({ filterProps, includeDrafts }) => {
    let filters = !includeDrafts
        ? {
              $and: [
                  {
                      $or: [
                          { gameBrand: { publishedAt: { $notNull: true } } },
                          {
                              gameBrand: { id: { $null: true } },
                          },
                      ],
                  },
              ],
          }
        : { $and: [] };
    if (filterProps.categoryId) {
        filters.$and.push({ categories: filterProps.categoryId });
    }
    if (filterProps.brandId) {
        filters.$and.push({
            gameBrand: {
                id: filterProps.brandId,
                ...(includeDrafts
                    ? {}
                    : {
                          publishedAt: {
                              $notNull: true,
                          },
                      }),
            },
        });
    }
    return filters;
};
export const MAKE_BETTING_FRIENDLY = 'r00dkc4b_s1_s1h7';

export default {
    MAKE_BETTING_FRIENDLY,
    unique,
    getLocalTypes,
};

export const getModelWithLocalisedName = (model, locale, key) => {
    let transformedModel = JSON.parse(JSON.stringify(model));
    const nameList = transformedModel[key];
    if (nameList && Array.isArray(nameList) && nameList.length) {
        const localisedValue = nameList.find((v) => v.locale === locale) ?? nameList.find((v) => v.locale === 'en') ?? nameList[0];
        transformedModel[key] = localisedValue.text;
    } else {
        transformedModel[key] = undefined;
    }
    return transformedModel;
};

export const getModelsWithLocalisedName = (data, locale, key) => {
    return data.map((model) => getModelWithLocalisedName(model, locale, key));
};

export const getGameWithLocalizedRibbonName = (game, locale) => {
    if (getObjectField(game, 'ribbon.data')) {
        let ribbon = flatDataAttributes(game.ribbon.data);
        game.ribbon = getModelWithLocalisedName(ribbon, locale, 'name');
    }
    return game;
};

export const getGamesWithLocalizedRibbonName = (games, locale) => {
    return games.map((game) => getGameWithLocalizedRibbonName(game, locale));
};
