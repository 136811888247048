export const casinoCollectionType = {
    homePage: 'homePage',
    slider: 'slider',
    grid: 'grid',
};

export const GAME_LAUNCHER_MODAL_NAME = 'game-launcher-modal';
export const GAME_DEPOSIT_MODAL_NAME = 'game-deposit-modal';
export const GAME_FAVOURITES_MODAL_NAME = 'game-favourites-modal';
export const REMOVE_GAME_FAVOURITES_MODAL_NAME = 'remove-game-favourites-modal';

export const casinoImageSizesValue = [100, 125, 200];
