import { widgetSize } from '@/js/plugins/cloudflare/const';

const DEFAULT_OPTIONS = {
    responseField: false,
    theme: 'light', // dark, auto
    retry: 'auto', // never|auto
    retryInterval: 8000, // when retry==='auto' -> value > 0 and < 900000
    size: widgetSize.NORMAL,
    language: 'auto',
    refreshExpired: 'never', // never|manual|auto
};

const renderType = {
    EXPLICIT: 'explicit',
    IMPLICIT: 'implicit',
};

const DEFAULT_API = {
    getResponse() {},
    implicitRender() {},
    ready() {},
    remove() {},
    render() {},
    reset() {},
};

let options = {
    resourceURL: 'https://challenges.cloudflare.com/turnstile/v0/api.js',
    render: '',
    name: 'turnstile',
    callbackName: 'onloadTurnstileCallback',
    id: '#turnstile-widget',
    isReady: false,
    bootstrap: false,
    widgetOptions: {
        'response-field': DEFAULT_OPTIONS.responseField,
        'refresh-expired': DEFAULT_OPTIONS.refreshExpired,
        retry: DEFAULT_OPTIONS.retry,
        theme: DEFAULT_OPTIONS.theme,
    },
};

let turnstile;

export default {
    install(Vue, _options = {}) {
        options = { ...options, ..._options };
        Vue.$cloudflare = Vue.prototype.$cloudflare = this.api;
    },
    api: {
        bootstrap(settings = {}) {
            const { resourceURL, callbackName, callback, expired, error, timeout, name, widgetOptions, id } = { ...options, ...settings };
            if (typeof document === 'undefined' || typeof window === 'undefined') {
                return;
            }
            return new Promise((resolve, reject) => {
                if (window[name] === null || !window[name]) {
                    const script = document.createElement('script');
                    script.src = `${resourceURL}?onload=${callbackName}`;
                    script.async = true;
                    script.defer = true;
                    script.onload = resolve;
                    script.onerror = reject;
                    document.head.appendChild(script);
                } else {
                    resolve();
                }
            })
                .then(() => {
                    this.turnstile = {
                        ...DEFAULT_API,
                        ...window[name],
                    };
                    if (!window[callbackName]) {
                        window[callbackName] = () => {
                            this.render(callback, expired, error, timeout, widgetOptions, id);
                        };
                    } else {
                        this.render(callback, expired, error, timeout, widgetOptions, id);
                    }
                })
                .catch((error) => {
                    this.turnstile = DEFAULT_API;
                    throw new Error(error);
                });
        },
        render(callback, expiredCallback, errorCallback, timeoutCallback, widgetOptions = {}, id) {
            callback =
                callback ||
                function () {
                    console.log(arguments);
                };
            expiredCallback =
                expiredCallback ||
                function () {
                    console.log(arguments);
                };
            errorCallback =
                errorCallback ||
                function () {
                    console.log(arguments);
                };
            timeoutCallback =
                timeoutCallback ||
                function () {
                    console.log(arguments);
                };
            const { sitekey } = options;
            this.turnstile.remove();
            this.turnstile.render(id, {
                ...{ ...options.widgetOptions, ...widgetOptions },
                sitekey,
                callback,
                'expired-callback': expiredCallback,
                'error-callback': errorCallback,
                'timeout-callback': timeoutCallback,
            });
        },
        turnstile,
        options: {
            render: renderType,
        },
    },
};
