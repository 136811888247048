<template>
    <div class="casino-no-favs">
        <p class="casino-no-favs-text">
            {{ $t('ui.casinoLobby.noSavedFavourites') }}<br />
            <strong>{{ $t('ui.casinoLobby.hereAreOtherGames') }}</strong>
        </p>
        <CasinoCollectionSlider
            :games="games"
            :gamesCount="gamesCount"
            :numberOfGamesPerSlide="oneRowLimit"
            title=""
            :areCollectionsLoading="areGamesLoading"
        />
    </div>
</template>

<script>
import { action } from '@/store/store';
import { mapState } from 'vuex';
import CasinoCollectionSlider from '@/modules/casino/strapiComponents/components/CasinoCollectionSlider.vue';
import { sortType } from '@/js/casino-const';
import { fillGamesGapWithFakeGames } from '@/modules/casino/utils/fillGamesGapWithFakeGames';
import {
    getCasinoCollectionGames,
    getCasinoCollectionGamesCount,
    getCasinoCollectionIsLoading,
    getCasinoCollectionShouldLoad,
    getCasinoGamesLimitForGridPerRow,
} from '@/modules/casino/utils/CasinoCollection-utils';

export default {
    name: 'CasinoFavouritesEmpty',
    components: { CasinoCollectionSlider },
    props: {
        data: Object,
    },
    computed: {
        ...mapState({
            gamesCollection: (state) => state.games,
        }),
        games() {
            const games = getCasinoCollectionGames(
                {
                    filterProps: this.filterProps,
                    sortBy: sortType.POPULAR,
                    limit: this.gamesLimit,
                },
                this.gamesCollection
            );
            if (games.length === 0) {
                return [];
            }
            return fillGamesGapWithFakeGames(games, this.gamesLimit);
        },
        gamesCount() {
            return getCasinoCollectionGamesCount(this.loadProps, this.gamesCollection);
        },
        oneRowLimit() {
            return getCasinoGamesLimitForGridPerRow(this.$mq.size);
        },
        shouldLoadGames() {
            return getCasinoCollectionShouldLoad(this.loadProps, this.gamesCollection);
        },
        areGamesLoading() {
            return getCasinoCollectionIsLoading(this.loadProps, this.gamesCollection);
        },
        gamesLimit() {
            return getCasinoGamesLimitForGridPerRow(this.$mq.size) * 3; // 3 slides
        },
        filterProps() {
            return {};
        },
        loadProps() {
            return {
                filterProps: this.filterProps,
                sortBy: sortType.POPULAR,
                start: 0,
                limit: this.gamesLimit,
                includeDrafts: this.$route.query.includeDrafts,
            };
        },
    },
    watch: {
        loadProps: {
            immediate: true,
            handler() {
                if (!this.shouldLoadGames) {
                    return;
                }
                this.$store.dispatch(action.GET_CASINO_GAMES_STRAPI, this.loadProps);
            },
        },
    },
};
</script>
<style scoped lang="scss">
.casino-no-favs {
    margin: 0 -12px;

    .casino-no-favs-text {
        text-align: center;
        padding: 0 12px;
        @extend %small-details-text;
    }
}
</style>
