<template>
    <div class="boosted-events">
        <Spinner v-if="isEventsLoading" visible class="inset" />
        <div v-else-if="events.length" class="boosted-events-container">
            <Game :key="event.id" v-for="event in events" :event="event" :showMarketCount="true" />
        </div>
        <ErrorPage
            v-else
            class="boosted-events-empty"
            :title="$t('ui.boosted.noActiveEvents')"
            :sectionList="[$t('ui.boosted.moreEvents')]"
            :button="{ text: $t('ui.boosted.browseUpcoming'), emit: true }"
            @button:click="clickErrorButton"
        >
            <template slot="header">
                <img :src="noEventsImgSrc" alt="search picture" />
            </template>
        </ErrorPage>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sport, EventType, DEFAULT_TAKE } from '@agi.packages/sport';
import { buildEventsQueryParams } from '@agi.packages/sport/utils/api/build-events-query-params';
import { getter as generalGetter } from '@/store/store';
import { getter as coreGetter } from '@/modules/core';
import { routeName } from '@/router/const-name';
import { ERROR_IMAGES } from '@/components/content/content-const';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import Game from '../Game.vue';
import ErrorPage from '@/components/Pages/ErrorPage.vue';

export default {
    name: 'BoostedEventsView',
    mixins: [PageMixin, SEOMixin],
    components: { ErrorPage, Game },
    computed: {
        ...mapGetters({
            events: sport.getter.GET_BOOSTED_EVENTS,
            sortedSportCategories: generalGetter.GET_SORTED_CATEGORIES,
            isLoading: coreGetter.IS_LOADING,
        }),
        isEventsLoading() {
            return this.isLoading(sport.action.GET_EVENTS);
        },
        noEventsImgSrc() {
            return ERROR_IMAGES.errorNoBoostedEvents;
        },
    },
    created() {
        const categoryIds = this.sortedSportCategories.map((c) => c.id);

        this.$store.dispatch(
            sport.action.GET_EVENTS,
            buildEventsQueryParams({
                categories: categoryIds,
                eventType: EventType.UPCOMING,
                take: DEFAULT_TAKE,
                onlyBoosted: true,
            })
        );
    },
    methods: {
        clickErrorButton() {
            this.$router.push({ name: routeName.UPCOMING });
        },
    },
};
</script>

<style scoped lang="scss">
.boosted-events {
    position: relative;

    &-empty {
        text-align: center;

        & img {
            max-width: 190px;
            width: 100%;
            margin-bottom: 12px;
        }
    }
}
</style>
