import { mapGetters, mapState } from 'vuex';
import { getter as platformGetter } from '@agi.packages/platform';
import { getter as generalGetter } from '@/store/store';
import { betslip } from '@agi.packages/sport';
import { config, helper } from '@agi.packages/core';
import { SEO_IMAGES } from '@/components/content/content-const';
import { routeName } from '@/router/const-name';
import { defaultCasinoPath } from '@/modules/platform/const/casino-const';

function createListItem(position, name, item) {
    return {
        '@type': 'ListItem',
        position,
        name,
        item,
    };
}

export default {
    computed: {
        ...mapState({
            currencySymbol: (state) => state.platform.settings.currency.symbol,
            minStake: (state) => state.platform.settings.betting.real.minStake,
            maxItems: (state) => state.platform.settings.betting.real.maxItems,
            brandName: (state) => state.platform.settings.brand.brand,
        }),
        ...mapGetters({
            cmsPages: platformGetter.GET_PAGES,
            bonusPercentage: betslip.getter.GET_MAX_BONUS_PERCENTAGE,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            categories: generalGetter.GET_SORTED_CATEGORIES,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
            bigWin: platformGetter.GET_BIG_WIN,
        }),
        cmsPage() {
            const pageSlug = this.$route.path.split('/').pop();
            return this.cmsPages[pageSlug];
        },
    },
    watch: {
        $route() {
            this.initSEO();
        },
        cmsPage(data) {
            if (data?.seo) {
                this.setTitle(null, null, data.seo?.title);
                this.setDescription(null, null, data.seo?.description);
                this.setSocialImage(data.seo?.image?.data?.attributes.url);
            }
        },
    },
    mounted() {
        if (!this.cmsPage) {
            this.initSEO();
        }
    },
    methods: {
        initSEO() {
            this.setSEOTags(this.$route);
        },
        setSEOTags(route, mergeParams = {}) {
            const seoMeta = route.meta.seo;
            if (seoMeta && seoMeta.skipOnCreated) {
                return;
            }
            const templateName = (seoMeta && seoMeta.template) || 'other';
            const pageName = (seoMeta && seoMeta.page) || (route.path && route.path.substring(1)) || '';
            const sport =
                route.params &&
                route.params.category &&
                ((this.categories.find((slug) => slug.Slug === route.params.category) || {}).Name || route.params.category);
            const { countryName, countryAbbreviation } = this.brandPreference;
            const casinoType = this.brandPreference.casinoType || {};
            const casinoPath = casinoType.path || defaultCasinoPath;
            const params = {
                brandName: this.$t('project.brand'),
                casinoName: this.$t(`ui.casinoLobby.${casinoPath}`),
                brandCountry: this.$t(`countries.${countryAbbreviation || countryName}`, { indefinite: true }) || countryName,
                minStake: this.minStake,
                maxItems: this.maxItems,
                currencySymbol: this.currencySymbol,
                bonusPercentage: this.bonusPercentage,
                winBonus: this.$t(`ui.common.${this.countryCodeIs.KE ? 'plainWinBoost' : 'plainWinBonus'}`),
                keyword: this.$t(`seo.keywords.${pageName}`, { indefinite: true }) || helper.capitalize(pageName.replace(/-/g, ' ')),
                ...(sport && { sport: this.$t(`seo.keywords.${sport}`) }),
                ...mergeParams,
            };
            const { pageTitle } = seoMeta || {};
            this.setTitle(templateName, params, pageTitle || '');
            this.setDescription(templateName, params);
            this.setOpenGraphUrl(route.path || this.$route.path);

            const { bigWin } = this.$route.query;
            const imageUrl = bigWin && !!this.bigWin.seoImage ? this.bigWin.seoImage : this.getImageUrlByTemplate(templateName);
            this.setSocialImage(imageUrl);
            this.setBreadcrumbs(route.path || this.$route.path, templateName, params);
        },
        setTitle(templateName, params, seoTitle) {
            const title = document.getElementsByTagName('title').item(0);
            const ogTitle = document.querySelector('[property="og:title"]');
            const twitterTitle = document.querySelector('[name="twitter:title"]');

            if (seoTitle) {
                title.text = seoTitle;
                ogTitle.content = seoTitle;
                twitterTitle.content = seoTitle;
            } else {
                const template =
                    this.$t(`project.seo.title.${templateName}`, { indefinite: true }) ||
                    this.$t(`seo.title.${templateName}`, { indefinite: true });
                /**
                 * @param {{brandCountry}} set title brand country name to all paths
                 */
                const newTitleText = helper.interpolate(`${template} {{brandCountry}}`, params).replace('_', ' ');
                if (newTitleText !== title.text) {
                    title.text = newTitleText;
                    ogTitle.content = newTitleText;
                    twitterTitle.content = newTitleText;
                }
            }
        },
        setDescription(templateName, params, seoDescription) {
            const template =
                this.$t(`project.seo.description.${templateName}`, { indefinite: true }) ||
                this.$t(`seo.description.${templateName}`, { indefinite: true });
            const description = document.getElementsByName('description').item(0);
            const ogDescription = document.querySelector('[property="og:description"]');
            const twitterDescription = document.querySelector('[name="twitter:description"]');

            if (seoDescription) {
                description.content = seoDescription;
                ogDescription.content = seoDescription;
                twitterDescription.content = seoDescription;
            } else {
                const newDescriptionContent = helper.interpolate(template, params);
                if (newDescriptionContent !== description.content) {
                    description.content = newDescriptionContent;
                    ogDescription.content = newDescriptionContent;
                    twitterDescription.content = newDescriptionContent;
                }
            }
        },
        setOpenGraphUrl(path) {
            const ogUrl = document.querySelector('[property="og:url"]');
            const newUrl = window.location.origin + path;
            if (newUrl !== ogUrl.content) {
                ogUrl.content = newUrl;
            }
        },
        setSocialImage(seoImage) {
            const ogImage = document.querySelector('[property="og:image"]');
            const twitterImage = document.querySelector('[name="twitter:image"]');

            ogImage.content = seoImage;
            twitterImage.content = seoImage;
        },
        setBreadcrumbs(routePath, templateName, params) {
            const ldJson = document.querySelector('[type="application/ld+json"]');

            if (!['country', 'group', 'event'].includes(templateName)) {
                ldJson.text = '';
                return;
            }

            const breadcrumbList = {
                '@context': 'https://schema.org/',
                '@type': 'BreadcrumbList',
                itemListElement: [createListItem(1, params?.brandName || this.brandName, config.getCurrentUrl())],
            };

            const countryPath = this.$router.resolve({
                name: routeName.COUNTRY,
                params: { id: params.countryId },
            }).href;

            const countryName = this.$t('seo.breadcrumbs.events', { country: params.country });
            breadcrumbList.itemListElement.push(createListItem(2, countryName, config.getCurrentUrl() + countryPath));

            if (templateName === 'group' || templateName === 'event') {
                const groupPath = this.$router.resolve({
                    name: routeName.GROUP,
                    params: { id: params.leagueId },
                }).href;

                breadcrumbList.itemListElement.push(createListItem(3, params.league, config.getCurrentUrl() + groupPath));
            }

            if (templateName === 'event') {
                breadcrumbList.itemListElement.push(createListItem(4, params.match, config.getCurrentUrl() + routePath));
            }

            ldJson.text = JSON.stringify(breadcrumbList);
        },
        getProdUrl(domain, path) {
            return `https://www.${domain}${path}`;
        },
        getImageUrlByTemplate(template) {
            const defaultSeoImage = SEO_IMAGES.seoSocial;
            const seoImage = SEO_IMAGES[template];
            return window.location.origin + (seoImage || defaultSeoImage);
        },
    },
};
