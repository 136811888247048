<template>
    <Modal
        :name="`casino-modal-${game.id}`"
        :options="{ overlay: true }"
        :width="modalWidth"
        :fixed="true"
        @close="closeModal"
        v-if="modalType"
    >
        <div class="title">{{ modalName }}</div>
        <template v-if="modalType === REMOVE_GAME_FAVOURITES_MODAL_NAME">
            <p class="description">{{ $t('ui.casinoLobby.removeFavouritesModal.description') }}</p>
            <button
                class="button button-primary button-full"
                @click="removeFavoriteCasinoGames(game)"
                id="casino-lobby-remove-favourite-remove-button-click"
            >
                {{ $t('ui.casinoLobby.removeFavouritesModal.remove') }}
            </button>
            <button class="button button-accent button-full" id="casino-lobby-remove-favourite-cancel-button-click" @click="closeModal">
                {{ $t('ui.casinoLobby.removeFavouritesModal.cancel') }}
            </button>
        </template>
        <template v-else-if="modalType === GAME_FAVOURITES_MODAL_NAME">
            <p class="description">{{ favoritesModalText }}</p>
            <button
                class="button button-primary button-full"
                @click="modalButtonClick(routeName.JOIN_NOW)"
                id="casino-lobby-signup-button-click"
            >
                {{ $t('ui.common.joinNow') }}
            </button>
            <button
                class="button button-accent button-full"
                id="casino-lobby-login-button-click"
                @click="
                    modalButtonClick(routeName.LOGIN, {
                        returnPath: `/${casinoPath}`,
                        filter: $route.query.filter,
                        favouriteCandidateGameId: game.id,
                    })
                "
            >
                {{ $t('ui.common.login') }}
            </button>
        </template>
        <template v-else-if="modalType === GAME_LAUNCHER_MODAL_NAME">
            <p class="description">{{ $t('ui.casinoLobby.gameLaunchModalText') }}</p>
            <button
                class="button button-primary button-full"
                @click="modalButtonClick(routeName.JOIN_NOW)"
                id="casino-lobby-signup-button-click"
            >
                {{ $t('ui.common.joinNow') }}
            </button>
            <button
                class="button button-accent button-full"
                id="casino-lobby-login-button-click"
                @click="
                    modalButtonClick(routeName.LOGIN, {
                        returnPath: `${routePath.CASINO_GAMES}/${game.slug}`,
                    })
                "
            >
                {{ $t('ui.common.login') }}
            </button>
        </template>
        <template v-else-if="modalType === GAME_DEPOSIT_MODAL_NAME">
            <p class="description">{{ $t('ui.casinoLobby.gameDepositModalText') }}</p>
            <button
                class="button button-primary button-full"
                @click="modalButtonClick(routeName.DEPOSIT)"
                id="casino-lobby-deposit-button-click"
            >
                {{ $t('ui.casinoLobby.depositNow') }}
            </button>
        </template>
    </Modal>
</template>

<script>
import {
    GAME_DEPOSIT_MODAL_NAME,
    GAME_FAVOURITES_MODAL_NAME,
    GAME_LAUNCHER_MODAL_NAME,
    REMOVE_GAME_FAVOURITES_MODAL_NAME,
} from '@/modules/casino/utils/CasinoCollection-const';
import { gameCategory } from '@/js/casino-const';
import { mapGetters } from 'vuex';
import { getter } from '@/store/store';
import { routeName } from '@/router/const-name';
import { routePath } from '@/router/const-path';

export default {
    name: 'CasinoCollectionGameTileModals',
    props: {
        game: Object,
        modalName: String,
        modalType: String,
    },
    data() {
        return {
            GAME_LAUNCHER_MODAL_NAME,
            GAME_DEPOSIT_MODAL_NAME,
            GAME_FAVOURITES_MODAL_NAME,
            REMOVE_GAME_FAVOURITES_MODAL_NAME,
            routeName,
            routePath,
        };
    },
    computed: {
        ...mapGetters({
            favoriteGamesIds: getter.GET_FAVORITE_GAMES_IDS,
        }),
        modalWidth() {
            return this.modalType === GAME_LAUNCHER_MODAL_NAME ? '312px' : '330px';
        },
        favoritesModalText() {
            return this.currentTab.key === gameCategory.FAVOURITES
                ? this.$t('ui.casinoLobby.favouritesModal.description')
                : this.$t('ui.casinoLobby.notFavouritesTabModal.description');
        },
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        removeFavoriteCasinoGames() {
            this.closeModal();
            this.$emit('removeFavoriteCasinoGames');
        },
        modalButtonClick(routeName, query) {
            this.$router.push({ name: routeName, query });
        },
    },
};
</script>

<style scoped lang="scss">
.modal-mask {
    .title {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $grey-text;
        margin-bottom: 8px;
    }

    .description {
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: $grey-text;
        margin-bottom: 24px;
    }

    .button {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
