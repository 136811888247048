const mapComponentNameToTurnstile = {
    AccountVerification: getAvailableNaming('AccountVerification'),
    JoinNowForm: getAvailableNaming('JoinNow'),
    LoginForm: getAvailableNaming('Login'),
    Registration: getAvailableNaming('Registration'),
    ResetPassword: getAvailableNaming('ResetPassword'),
};

function toTurnstileName(name) {
    return name
        .trim()
        .toLowerCase()
        .replace(/([ -._]+)/g, '');
}

function getAvailableNaming(componentName) {
    const baseName = toTurnstileName(componentName);
    const postfixes = ['page', 'form', 'route', 'component'];

    return [baseName, ...postfixes.map((pf) => baseName + pf)];
}

export function getComponentsWithCaptcha(turnstiles) {
    const hash = turnstiles.reduce((acc, turnstile) => {
        const turnstileName = toTurnstileName(turnstile);

        for (const [componentName, list] of Object.entries(mapComponentNameToTurnstile)) {
            if (list.includes(turnstileName)) {
                acc[componentName] = null;

                break;
            }
        }

        return acc;
    }, {});

    return Object.freeze(Object.keys(hash));
}
