export const isFavGame = ({ favoriteGamesIds, importedIDs, gameId }) => {
    const isFavInOldSystem = importedIDs ? favoriteGamesIds.old.some((oldId) => importedIDs.some((id) => id === `${oldId}`)) : false;
    const isFavInStrapi = favoriteGamesIds.strapi.some((strapiId) => strapiId === gameId);

    return {
        isFav: isFavInOldSystem || isFavInStrapi,
        isFavInOldSystem,
        isFavInStrapi,
    };
};
