<template>
    <div
        v-if="background"
        class="responsive-background"
        :style="{
            backgroundImage: `url(${$mq.isHighRes && image.url2x ? image.url2x : image.url})`,
        }"
    >
        <slot />
    </div>
    <img
        v-else-if="images || single"
        :class="[`responsive${single ? '-single' : ''}-image`]"
        :src="image.url"
        @error="$emit('error', $event)"
    />
</template>
<script>
import { deviceType } from '@agi.packages/core';

export default {
    name: 'ResponsiveImage',
    props: {
        single: String,
        images: Array,
        background: Array,
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            image: {},
            isPresto: deviceType.isPresto(),
        };
    },
    created() {
        this.isPresto && this.handleResize();
    },
    mounted() {
        !this.isPresto && this.handleResize();
        !this.single && window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        source() {
            return (this.images || this.background || [{ url: this.single }]).map((img) => {
                const { href } = this.getUrl(img.url);
                return { ...img, url: href };
            });
        },
    },
    methods: {
        getUrl(urlString) {
            // TODO: move to package(core || platform) & re-use BP-16141
            try {
                const url = new URL(urlString, document.baseURI);
                const { pathname } = url;
                if (url.origin === new URL(document.URL, document.baseURI).origin) {
                    return urlString === pathname ? url : { href: urlString ? `/${urlString}` : '' };
                }
                return url;
            } catch (e) {
                return {};
            }
        },
        handleResize() {
            const width = this.isPresto || this.single ? window.innerWidth : this.$el.getBoundingClientRect().width;
            const matchedImage = this.source.find((item, index) => {
                const matchesSize = width <= item.width;
                const isLast = index === this.source.length - 1;
                return matchesSize || isLast;
            });
            if (this.image.width !== matchedImage.width || this.single) {
                this.image = { ...matchedImage };
            }
        },
    },
};
</script>

<style scoped lang="scss">
.responsive-background {
    background: no-repeat center center;
    background-size: cover;
}

.responsive-image {
    width: 100%;
}
</style>
