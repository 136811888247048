<template>
    <div class="page registration-page">
        <Spinner :visible="fetchingRegions" class="align-center inset viewport-padding" />
        <form v-if="!fetchingRegions" v-on:submit.prevent>
            <h2>{{ $t('ui.common.joinNow') }}</h2>
            <renderer v-if="!inProgress && error" class="notify error" :input="error" />
            <div class="notify error" v-if="$v && $v.form.idType && $v.form.idType.$dirty && $v.form.idType.$error">
                {{ $t('ui.registration.error.pickIdType') }}
            </div>
            <template v-for="field in validationScenariosFields">
                <PhoneNumber
                    v-if="field.fieldName === signupFieldType.PHONE_NUMBER"
                    :key="field.fieldName"
                    :formName="formNameId"
                    :value="form.phoneNumber"
                    @value="form.phoneNumber = $event"
                    :helpText="$t(`ui.countries.${jurisdictionCountry}.form.help.phoneNumber`, { indefinite: true })"
                    :v="$v.form.phoneNumber"
                />
                <Password
                    v-if="field.fieldName === signupFieldType.PASSWORD"
                    :key="field.fieldName"
                    :formName="formNameId"
                    :value="form.password"
                    @value="form.password = $event"
                    :v="$v.form.password"
                />
                <InputField
                    v-if="field.fieldName === signupFieldType.FIRST_NAME"
                    :key="field.fieldName"
                    name="firstName"
                    :formName="formNameId"
                    :value="form.firstName"
                    :attrs="{ maxLength: 40 }"
                    @value="form.firstName = $event"
                    :v="$v.form.firstName"
                    :errorMessages="errorMessages.firstName"
                >
                    <template slot="label">{{ $t('ui.common.user.firstName') }}</template>
                </InputField>
                <InputField
                    v-if="field.fieldName === signupFieldType.LAST_NAME"
                    :key="field.fieldName"
                    name="lastName"
                    :formName="formNameId"
                    :value="form.lastName"
                    :attrs="{ maxLength: 40 }"
                    @value="form.lastName = $event"
                    :v="$v.form.lastName"
                    :errorMessages="errorMessages.lastName"
                >
                    <template slot="label">{{ $t('ui.common.user.lastName') }}</template>
                </InputField>
                <DOB
                    name="dob"
                    v-if="field.fieldName === signupFieldType.DATE_OF_BIRTH"
                    :key="field.fieldName"
                    :formName="formNameId"
                    @value="form.dob = $event"
                />
                <template v-if="field.fieldName === signupFieldType.REGION_ID">
                    <label :key="`${field.fieldName}-label`" :for="`${formNameId}-location`" class="form">
                        {{ $t('ui.common.user.location') }}
                    </label>
                    <select
                        :key="`${field.fieldName}-region`"
                        type="text"
                        name="location"
                        :id="`${formNameId}-location`"
                        v-model="form.location"
                        class="form global-select"
                        @change="$v.form.location.$touch()"
                        :class="[$v && $v.form.location.$dirty && ($v.form.location.$error ? 'error' : 'valid')]"
                    >
                        <option value="">{{ $t('ui.common.select') }} {{ $t(`project.ui.region.${jurisdictionCountry}`) }}</option>
                        <option v-for="region of regions" :key="region.id" :value="region.id">{{ region.name }}</option>
                    </select>
                </template>
                <template v-if="field.fieldName === signupFieldType.NATIONALITY">
                    <label :key="`${field.fieldName}-label`" :for="`${formNameId}-nationality`" class="form">
                        {{ $t(`ui.countries.${jurisdictionCountry}.form.label.nationality`, { indefinite: true }) }}
                    </label>
                    <select
                        name="nationality"
                        :key="field.fieldName"
                        type="text"
                        :id="`${formNameId}-nationality`"
                        v-model="form.nationality"
                        @change="onNationalityChange"
                        class="form global-select"
                        :class="[$v && $v.form.nationality.$dirty && ($v.form.nationality.$error ? 'error' : 'valid')]"
                    >
                        <option v-for="(nationality, index) of nationalities" :key="`${formNameId}-option-${index}`" :value="nationality">
                            {{ nationality }}
                        </option>
                    </select>
                </template>
                <template v-if="field.fieldName === signupFieldType.IDENTIFICATION_TYPE">
                    <label
                        v-show="!idNumberField.hidden"
                        :key="`${field.fieldName}-label-idType`"
                        :for="`${formNameId}-idType`"
                        class="form"
                    >
                        {{ $t('ui.payment.payout.ghana.idType') }}
                    </label>
                    <select
                        :key="`${field.fieldName}-idType`"
                        type="text"
                        name="idType"
                        :id="`${formNameId}-idType`"
                        v-model="form.idType"
                        v-show="!idNumberField.hidden"
                        :disabled="idNumberField.locked"
                        @change="$v.form.idType.$touch()"
                        class="form warn global-select"
                        :class="[$v && $v.form.idType.$dirty && ($v.form.idType.$error ? 'error' : 'valid')]"
                    >
                        <option :value="null" hidden>
                            {{ $t('ui.payment.payout.ghana.selectType') }}
                        </option>
                        <option v-for="({ key }, index) in idTypeOptions" :key="`${key}-${index}`" :value="key">
                            {{ $t(`ui.countries.${jurisdictionCountry}.form.label.${key}`) }}
                        </option>
                    </select>
                </template>
                <template v-if="field.fieldName === signupFieldType.IDENTIFICATION_NUMBER">
                    <InputField
                        name="idNumber"
                        :key="`${field.fieldName}-idNumber`"
                        type="text"
                        :formName="formNameId"
                        :value="form.idNumber"
                        :attrs="{
                            autocomplete: 'off',
                            ...(idNumberField.maxLength && { maxLength: idNumberField.maxLength }),
                        }"
                        @value="onIdNumberChange"
                        :helpText="form.idType && $t(`ui.countries.${jurisdictionCountry}.form.help.${form.idType}`, { indefinite: true })"
                        :v="$v.form.idNumber"
                        :errorMessages="errorMessages.idNumber"
                    >
                        <template slot="label">
                            {{
                                $t(`ui.countries.${jurisdictionCountry}.form.label.${idNumberField.key}`, { indefinite: true }) + ' Number'
                            }}
                        </template>
                        <template v-if="form.idType === 'GHANA_CARD'">
                            <div class="ghana-id">GHA</div>
                        </template>
                    </InputField>
                </template>
            </template>

            <TurnstileWidget :name="$options.name" />

            <input
                type="submit"
                @click="register()"
                class="button button-submit button-full"
                :value="$t('ui.common.joinNow').toUpperCase()"
                :disabled="!token || inProgress || (!isPresto && $v.$invalid)"
            />
            <div class="terms">
                <div class="terms-icon">
                    <SvgIcon iconId="icon-square-checkbox-checked" verticalAlign="text-top" />
                </div>
                <div class="terms-text">
                    {{ agreementText }}
                    <router-link :to="{ path: '/terms' }" class="underline margin-top">
                        {{ $t('ui.registration.termsAndConditions') }}
                    </router-link>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required, minLength, numeric } from 'vuelidate/lib/validators';
import { helper, deviceType, VALIDATORS, NATIONALITIES, getter as coreGetter } from '@agi.packages/core';
import { InputField, Spinner, DOB } from '@agi.packages/core/components';
import { auth, action as generalAction, VERIFICATION_ERRORS, getter as platformGetter } from '@agi.packages/platform';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import PhoneNumber from '@/components/PhoneNumber.vue';
import Password from '@/components/Password.vue';

import AuthenticationMixin from '../mixins/Authentication.mixin';
import { routeName } from '@/router/const-name';
const signupFieldType = {
    PHONE_NUMBER: 'phoneNumber',
    PASSWORD: 'password',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    DATE_OF_BIRTH: 'dateOfBirth',
    REGION_ID: 'regionId',
    NATIONALITY: 'nationality',
    IDENTIFICATION_NUMBER: 'identificationNumber',
    IDENTIFICATION_TYPE: 'identificationType',
};
const idTypeOptionsByJurisdiction = {
    GH: [
        { key: 'NON_NATIONAL', suffix: '_LABEL', nonNational: true, locked: true, maxLength: 30 },
        { key: 'GHANA_CARD', maxLength: 30 },
        { key: 'VOTER', maxLength: 10 },
        { key: 'PASSPORT', maxLength: 8 },
        { key: 'DRIVER_LICENCE', maxLength: 18 },
        { key: 'SSNIT', maxLength: 13 },
        { key: 'OLD_VOTER', maxLength: 10 },
    ],
    UG: [
        { key: 'NON_NATIONAL', nonNational: true, hidden: true, maxLength: 30 },
        { key: 'NIN', hidden: true, maxLength: 14 },
    ],
};
export default {
    name: 'Registration',
    components: { InputField, PhoneNumber, Password, Spinner, DOB },
    mixins: [PageMixin, AuthenticationMixin, SEOMixin],
    data() {
        return {
            formNameId: 'registration-form',
            form: {
                phoneNumber: null,
                password: '',
                firstName: '',
                lastName: '',
                location: '',
                idNumber: '',
                idType: null,
                nationality: '',
                dob: '',
            },
            isPresto: deviceType.isPresto(),
            signupFieldType,
        };
    },
    validations() {
        const isPin = this.brandPreference.pin;
        const validators = {
            form: {
                phoneNumber: {
                    required,
                },
                password: {
                    required,
                    minLength: minLength(4),
                    ...(isPin && { numeric }),
                },
            },
        };
        for (const field of this.validationScenariosFields) {
            switch (field.fieldName) {
                case signupFieldType.FIRST_NAME:
                    validators.form.firstName = { name: VALIDATORS.name };
                    break;
                case signupFieldType.LAST_NAME:
                    validators.form.lastName = { name: VALIDATORS.name };
                    break;
                case signupFieldType.NATIONALITY:
                    validators.form.nationality = { required };
                    break;
                case signupFieldType.IDENTIFICATION_NUMBER:
                    const idNumber =
                        this.form.idType && (VALIDATORS[`${this.jurisdictionCountry}_${this.form.idType}`] || VALIDATORS[this.form.idType]);
                    validators.form.idNumber = {
                        ...(idNumber && { idNumber }),
                        required,
                    };
                    break;
                case signupFieldType.IDENTIFICATION_TYPE:
                    validators.form.idType = { required };
                    break;
                case signupFieldType.DATE_OF_BIRTH:
                    validators.form.dob = { required };
                    break;
                case signupFieldType.REGION_ID:
                    validators.form.location = { required };
                    break;
            }
        }
        return validators;
    },

    created() {
        if (this.storedPhoneNumber) {
            this.form.phoneNumber = helper.removeCountryCode(this.storedPhoneNumber, this.phonePrefix);
        }
        this.form.nationality = this.nationality;
        if (this.isFieldExist(signupFieldType.REGION_ID)) {
            this.$store.dispatch(generalAction.FETCH_REGIONS);
        }
    },
    computed: {
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            token: auth.getter.SECURED_TOKEN,
            jurisdictionCountry: platformGetter.GET_COUNTRY,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            turnstileError: auth.getter.GET_TURNSTILE_ERROR,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
        }),
        ...mapState({
            authError: (state) => state.platform.auth.error,
            user: (state) => state.platform.settings.user,
            phonePrefix: (state) => state.platform.settings?.brandIdentity?.phoneCountryCode,
            storedPhoneNumber: (state) => state.platform.auth.phoneNumber,
            regions: (state) => state.platform.regions,
            validationScenariosFields: (state) => state.platform.settings?.brandIdentity?.validationScenarios?.signUp?.fields,
        }),
        agreementText() {
            return this.brandPreference.age
                ? this.$t('ui.registration.agreementWithAge', { indefinite: true, age: this.brandPreference.age })
                : this.$t('ui.registration.agreement');
        },
        errorMessages() {
            const idNumber = this.$t(`ui.countries.${this.jurisdictionCountry}.form.error.${this.form.idType}`, { indefinite: true });
            const name = this.$t('ui.common.form.error.name');
            return {
                firstName: { name },
                lastName: { name },
                ...(idNumber && { idNumber: { idNumber } }),
            };
        },
        inProgress() {
            return this.isLoading(auth.action.REGISTER);
        },
        fetchingRegions() {
            return this.isLoading(generalAction.FETCH_REGIONS);
        },
        nationality() {
            return this.brandPreference.nationality || '';
        },
        nationalityByJur() {
            return this.form.nationality === this.nationality;
        },
        nationalities() {
            const nationality = this.nationality;
            return NATIONALITIES.sort((x, y) => (x === nationality ? -1 : y === nationality ? 1 : 0));
        },
        idTypeOptions() {
            return (
                (idTypeOptionsByJurisdiction[this.jurisdictionCountry] || []).filter(
                    ({ nonNational }) => !nonNational === this.nationalityByJur
                ) || {}
            );
        },
        idNumberField() {
            const option = (this.form.idType && this.idTypeOptions.find(({ key }) => key === this.form.idType)) || {
                key: 'NON_NATIONAL_LABEL',
            };
            return {
                ...option,
                key: option.suffix ? option.key + option.suffix : option.key,
            };
        },
        error() {
            return this.turnstileError || this.authError;
        },
    },
    methods: {
        register() {
            if (this.isPresto && this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            const data = {
                phoneNumber: this.form.phoneNumber,
                password: this.form.password,
                country: this.jurisdictionCountry,
                ...(this.isFieldExist(signupFieldType.FIRST_NAME) && {
                    firstName: helper.normalizeName(this.form.firstName),
                }),
                ...(this.isFieldExist(signupFieldType.LAST_NAME) && {
                    lastName: helper.normalizeName(this.form.lastName),
                }),
                ...(this.isFieldExist(signupFieldType.REGION_ID) && {
                    regionId: this.form.location,
                }),
                ...(this.isFieldExist(signupFieldType.NATIONALITY) && {
                    nationality: this.form.nationality.toUpperCase(),
                }),
                ...(this.isFieldExist(signupFieldType.IDENTIFICATION_NUMBER) && {
                    identificationNumber: this.countryCodeIs.GH ? this.setGhanaIDNumber() : this.form.idNumber,
                }),
                ...(this.isFieldExist(signupFieldType.IDENTIFICATION_TYPE) && {
                    identificationType: this.form.idType,
                }),
                ...(this.isFieldExist(signupFieldType.DATE_OF_BIRTH) && {
                    dateOfBirth: this.form.dob,
                }),
            };
            this.$store.dispatch(auth.action.REGISTER, data).catch(({ errorCode } = {}) => {
                errorCode && this.$scroll.scrollTo(0);
                if (VERIFICATION_ERRORS.includes(errorCode)) {
                    this.$router.push({
                        name: routeName.ACCOUNT_VERIFICATION,
                        params: {
                            phoneNumber: this.form.phoneNumber,
                            verificationCodeSent: true,
                            isPasswordRequired: false,
                        },
                    });
                    this.$store.commit(auth.mutation.SET_OTP_TIMER, new Date().getTime());
                }
            });
        },
        onNationalityChange() {
            this.form.idType = null;
            this.$v.form.nationality.$touch();
        },
        onIdNumberChange(value) {
            if (!this.form.idType) {
                this.$v.form.idType.$touch();
            }
            this.form.idNumber = value;
        },
        setGhanaIDNumber() {
            if (this.form.idType === 'GHANA_CARD') {
                const ghanaId = this.form.idNumber.replace(/\D/g, '');
                return `GHA-${ghanaId.slice(0, 9)}-${ghanaId.slice(9)}`;
            } else {
                return this.form.idNumber;
            }
        },
        isFieldExist(fieldName) {
            return this.validationScenariosFields.some((field) => field.fieldName === fieldName);
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch(auth.action.RESET_ERROR);
        this.$store.commit(auth.mutation.SET_PHONE_NUMBER, {
            phoneNumber: this.form.phoneNumber,
            phonePrefix: this.phonePrefix,
        });
        next();
    },
    watch: {
        idTypeOptions(options) {
            if (options.length === 1) {
                this.form.idType = options[0].key;
                this.$v.form.idType.$touch();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.registration-page {
    @include only_mini_feature_phone {
        padding: 18px 10px;
    }
}

.terms {
    margin: 1rem 0;

    &-icon {
        display: inline;
        width: 16px;
        height: 16px;
        margin-right: 2px;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &-text {
        display: inline;
    }
}

.terms {
    font-size: 14px;
    color: $grey-text;
}

input {
    width: 100%;
}

.notify.error {
    margin: 0 0 14px;
}
// TODO: create SelectOptions component
select {
    &.error {
        border-color: $error-red;
        border-width: $input-focus-error-border-width;

        &.warn {
            background: $message-warning;
        }
    }

    &.valid {
        border-color: $greenish;
        border-width: $input-border-width;
    }

    &[disabled] {
        background: $light-grey;
        color: $grey-text;
    }

    option {
        background: $white-bg;
    }
}

.ghana-id {
    @extend %body-normal-font-400;
    color: $disabled-text;
    background-color: $disabled-bg;
    padding: 7px 8px;
    width: 44px;
    border: 1px solid $disabled-bg;
}
</style>
