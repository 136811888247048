<template>
    <div
        class="casino-game-brands"
        v-if="gameBrands.length > 0"
        v-dragscroll.x
        @dragscrollstart="dragscrollstart"
        @dragscrollend="dragscrollend"
    >
        <div v-for="brand in gameBrands" :key="brand.id" class="casino-brands-item" @click.capture="dragscrollclick">
            <CasinoGameBrandsTile :brand="brand" />
        </div>
    </div>
</template>

<script>
import { action, getter } from '@/store/store';
import { mapGetters } from 'vuex';
import { getter as coreGetter } from '@/modules/core';
import CasinoGameBrandsTile from '@/modules/casino/strapiComponents/components/CasinoGameBrandsTile.vue';
import ScrollDraggable from '@/js/mixins/ScrollDraggable.mixin';

export default {
    name: 'CasinoGameBrands',
    components: { CasinoGameBrandsTile },
    mixins: [ScrollDraggable],
    props: {
        data: Object,
    },
    computed: {
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            gameBrands: getter.GET_GAME_BRANDS,
        }),
        isAPILoading() {
            return this.isLoading(action.GET_CASINO_GAME_BRANDS);
        },
    },
    mounted() {
        if (this.isPresto || this.isAPILoading) {
            return;
        }

        this.$store.dispatch(action.GET_CASINO_GAME_BRANDS, { includeDrafts: this.$route.query.includeDrafts });
    },
};
</script>

<style scoped lang="scss">
.casino-game-brands {
    margin-top: 16px;
    margin-bottom: 16px;

    padding: 0 12px 0 12px;
    display: grid;
    grid-auto-flow: column;
    --gap: 18px;
    grid-gap: calc(var(--gap) - 6px);
    overflow-y: hidden;
    overflow-x: scroll;
    scrollbar-width: none;
    user-select: none;
    scroll-snap-type: x mandatory;

    --columns: 2;
    @include mq-xxs {
        --columns: 3;
    }

    @include mq-xs {
        --columns: 4;
    }

    @include mq-sm {
        --columns: 4;
    }

    @include mq-md {
        --columns: 4;
    }

    grid-auto-columns: calc((100% - (var(--columns) - 1) * var(--gap)) / var(--columns));

    .casino-brands-item {
        scroll-margin: 12px;
        user-select: none;
        scroll-snap-align: center;
    }
}
</style>
