<template>
    <div v-if="gamesCount > 0" class="game-counter" @click="openCasinoGames()">
        <span class="pointer">
            <span>{{ $t('ui.casinoLobby.moreGames') }}</span>
            <span class="bold">{{ gamesCount }}</span>
            <SvgIcon iconId="arrow_right" class="icon-size-very-small" verticalAlign="baseline" />
        </span>
    </div>
</template>

<script>
import { action } from '@/store/store';
import { mapState } from 'vuex';
import { routeName } from '@/router/const-name';
import { sortType } from '@/js/casino-const';
import { getCasinoCollectionGamesCount, getCasinoCollectionIsLoading } from '@/modules/casino/utils/CasinoCollection-utils';

export default {
    name: 'CasinoShowAllGames',
    computed: {
        ...mapState({
            gamesCollection: (state) => state.games,
        }),
        gamesCount() {
            return getCasinoCollectionGamesCount(this.fetchProps, this.gamesCollection);
        },
        isLoading() {
            return getCasinoCollectionIsLoading(this.fetchProps, this.gamesCollection);
        },
        fetchProps() {
            return {
                filterProps: {},
                start: 0,
                limit: 1,
                sortBy: sortType.POPULAR,
                includeDrafts: this.$route.query.includeDrafts,
            };
        },
    },
    methods: {
        openCasinoGames() {
            this.$router.push({ name: routeName.CASINO_GAMES });
        },
    },
    mounted() {
        if (this.isPresto || this.isLoading) {
            return;
        }

        this.$store.dispatch(action.GET_CASINO_GAMES_STRAPI, this.fetchProps);
    },
};
</script>
<style scoped lang="scss">
.game-counter {
    color: $betpawa-black;
    display: block;
    font-size: 14px;
    line-height: 14px;
    text-decoration: none;
    padding: 12px;
    text-align: center;

    .bold {
        padding: 0 2px 0 10px;
    }

    > span {
        border-bottom: 1px solid $betpawa-black;
        padding-bottom: 1px;
    }
}
</style>
