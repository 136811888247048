import { dragscroll } from 'vue-dragscroll';

const ScrollDraggable = {
    directives: {
        dragscroll: dragscroll,
    },
    data() {
        return {
            dragScrollTimer: null,
            dragScrollTimerEnding: null,
            isDragging: false,
        };
    },
    methods: {
        // Fixes desktop dragging which also fired the click in the end
        // https://github.com/donmbelembe/vue-dragscroll/issues/61#issuecomment-1058266843
        dragscrollstart() {
            clearTimeout(this.dragScrollTimerEnding);
            this.dragScrollTimer = setTimeout(() => (this.isDragging = true), 100);
        },

        dragscrollend() {
            clearTimeout(this.dragScrollTimer);
            clearTimeout(this.dragScrollTimerEnding);
            this.dragScrollTimerEnding = setTimeout(() => {
                this.isDragging = false;
            }, 50);
        },

        dragscrollclick(event) {
            if (this.isDragging) {
                event.stopPropagation();
            }
        },
    },
};

export default ScrollDraggable;
