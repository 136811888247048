<template>
    <Registration class="signup-wrapper" />
</template>

<script>
import Registration from '../Pages/Registration';

export default {
    name: 'Signup',
    components: { Registration },
};
</script>

<style scoped lang="scss">
.signup-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;

    ::v-deep .page {
        padding: 0;
    }
}
</style>
