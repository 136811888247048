import { widgetType } from '@agi.packages/sport/const/widget-const';

const defaultConfig = {
    wantedWidgetType: widgetType.SPORT_RADAR,
    convertIdToNumber: true,
    defaultValue: {},
};

export function getEventWidget(event, config = defaultConfig) {
    const { wantedWidgetType, convertIdToNumber, defaultValue } = { ...defaultConfig, ...config };

    if (event && Array.isArray(event.widgets)) {
        const foundWidget = event.widgets.find(({ type }) => type === wantedWidgetType);

        if (foundWidget) {
            return {
                ...foundWidget,
                id: convertIdToNumber ? Number(foundWidget.id) : foundWidget.id,
            };
        }
    }

    return defaultValue;
}
