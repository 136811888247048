<template>
    <div v-if="games.length">
        <CasinoCollectionGrid
            v-if="casinoData.type === casinoCollectionType.grid"
            :games="games"
            :gamesCount="gamesCount"
            :gamesLimit="gamesLimit"
            :title="title"
            :iconId="iconId"
            @openCasinoGames="openCasinoGames"
            :isFavourites="casinoData.showOnlyFavourites"
            :areCollectionsLoading="areCollectionsLoading"
            :showMoreGamesOnBottom="casinoData.showMoreGamesOnBottom"
            :isHomePage="casinoData.isHomePage"
        />
        <CasinoCollectionSlider
            v-else-if="casinoData.type === casinoCollectionType.slider"
            :games="games"
            :gamesCount="gamesCount"
            :numberOfGamesPerSlide="oneRowLimit"
            :title="title"
            :iconId="iconId"
            @openCasinoGames="openCasinoGames"
            :isFavourites="casinoData.showOnlyFavourites"
            :areCollectionsLoading="areCollectionsLoading"
            :showMoreGamesOnBottom="casinoData.showMoreGamesOnBottom"
            :isHomePage="casinoData.isHomePage"
        />
    </div>
</template>

<script>
import { action, getter } from '@/store/store';
import { mapGetters, mapState } from 'vuex';
import { casinoCollectionType } from '@/modules/casino/utils/CasinoCollection-const';
import CasinoCollectionGrid from '@/modules/casino/strapiComponents/components/CasinoCollectionGrid.vue';
import CasinoCollectionSlider from '@/modules/casino/strapiComponents/components/CasinoCollectionSlider.vue';
import { routeName } from '@/router/const-name';
import { sortType } from '@/js/casino-const';
import { getObjectField } from '@/modules/core/utils/helper';
import { fillGamesGapWithFakeGames } from '@/modules/casino/utils/fillGamesGapWithFakeGames';
import {
    getCasinoCollectionGames,
    getCasinoCollectionGamesCount,
    getCasinoCollectionIsLoading,
    getCasinoCollectionShouldLoad,
    getCasinoGamesLimitForGridPerRow,
} from '@/modules/casino/utils/CasinoCollection-utils';
import { getModelWithLocalisedName } from '@/store/utils';
import { getter as translationsGetter } from '@/store/modules/translations';
import { flatDataAttributes } from '@/modules/core/utils/strapi/flatDataAttributes';

export default {
    name: 'CasinoCollection',
    components: { CasinoCollectionGrid, CasinoCollectionSlider },
    props: {
        data: Object,
    },
    data() {
        return {
            casinoCollectionType: casinoCollectionType,
        };
    },
    computed: {
        ...mapGetters({
            favoriteGamesIds: getter.GET_FAVORITE_GAMES_IDS,
            selectedLanguage: translationsGetter.GET_SELECTED_LANGUAGE,
        }),
        ...mapState({
            gamesCollection: (state) => state.games,
        }),
        title() {
            if (this.casinoData.title) {
                return this.casinoData.title;
            }
            if (this.casinoData.showOnlyFavourites) {
                return this.$t(`ui.casinoLobby.favourites`);
            }
            if (this.categoryData) {
                return this.categoryData.name;
            }
            return this.$t(`ui.eventPage.category.all`);
        },
        iconId() {
            if (this.casinoData.iconId) {
                return this.casinoData.iconId;
            }
            if (this.casinoData.showOnlyFavourites) {
                return 'icon-star-tab';
            }
            if (this.categoryData) {
                return this.categoryData.iconId;
            }
            return null;
        },
        casinoData() {
            let { title, iconId, type, showMoreGamesOnBottom, sliderNumberOfSlides, showOnlyFavourites, gridNumberOfRows, isHomePage } =
                this.data.content;

            return {
                title,
                iconId,
                type,
                showMoreGamesOnBottom: showMoreGamesOnBottom ?? false,
                sliderNumberOfSlides: sliderNumberOfSlides ?? 4,
                gridNumberOfRows: gridNumberOfRows ?? 2,
                showOnlyFavourites: showOnlyFavourites ?? false,
                isHomePage: isHomePage ?? false,
            };
        },
        games() {
            const games = getCasinoCollectionGames(this.loadProps, this.gamesCollection);
            if (!games) {
                return [];
            }

            if (!this.areCollectionsLoading) {
                return games;
            }

            return fillGamesGapWithFakeGames(games, this.gamesLimit);
        },
        gamesCount() {
            return this.casinoData.showOnlyFavourites
                ? this.favoriteGamesIds.old.length + this.favoriteGamesIds.strapi.length
                : getCasinoCollectionGamesCount(this.loadProps, this.gamesCollection);
        },
        areCollectionsLoading() {
            return getCasinoCollectionIsLoading(this.loadProps, this.gamesCollection);
        },
        shouldLoadGames() {
            return getCasinoCollectionShouldLoad(this.loadProps, this.gamesCollection);
        },
        gamesLimit() {
            if (this.casinoData.type === casinoCollectionType.slider) {
                return this.oneRowLimit * this.casinoData.sliderNumberOfSlides;
            }
            return this.oneRowLimit * this.casinoData.gridNumberOfRows;
        },
        oneRowLimit() {
            return getCasinoGamesLimitForGridPerRow(this.$mq.size);
        },
        filterProps() {
            if (this.casinoData.showOnlyFavourites) {
                return { favourites: this.favoriteGamesIds };
            }
            return { categoryId: this.categoryData.id };
        },
        loadProps() {
            return {
                filterProps: this.filterProps,
                sortBy: this.casinoData.showOnlyFavourites ? sortType.FAVOURITES_ADDED_DESC : sortType.POPULAR,
                start: 0,
                limit: this.gamesLimit,
                includeDrafts: this.$route.query.includeDrafts,
            };
        },
        categoryData() {
            let casinoData = flatDataAttributes(getObjectField(this.data.content, 'casinoCat.data', {}));
            if (casinoData) {
                casinoData = getModelWithLocalisedName(casinoData, this.selectedLanguage, 'name');
            }
            return {
                ...casinoData,
                start: 0,
                limit: this.gamesLimit,
            };
        },
    },
    watch: {
        loadProps: {
            immediate: true,
            handler() {
                if (!this.shouldLoadGames) {
                    return;
                }
                this.$store.dispatch(action.GET_CASINO_GAMES_STRAPI, this.loadProps);
            },
        },
    },
    methods: {
        openCasinoGames() {
            let query = {
                category: this.categoryData.id ? `${this.categoryData.id}` : undefined,
                ...(this.$route.query.includeDrafts ? { includeDrafts: 'true' } : {}),
            };
            if (this.casinoData.showOnlyFavourites) {
                query = { favourites: 'true' };
            }
            this.$router.push({
                name: routeName.CASINO_GAMES,
                query,
            });
        },
    },
};
</script>
