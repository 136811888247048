import { getKeyForGamesInCategory } from '@/store/utils';
import { getObjectField } from '@/modules/core/utils/helper';
import { casinoImageSizesValue } from '@/modules/casino/utils/CasinoCollection-const';

export const getCasinoGamesLimitForGridPerRow = (currentSize) => {
    if (!currentSize) {
        // bigger than xm
        return 4;
    }
    const oneRowLimitPerSize = { vxs: 2, ivxs: 2, xxxs: 2, xxs: 3, xs: 4, sm: 4, md: 4 };
    return oneRowLimitPerSize[currentSize];
};
export const getCasinoCollectionShouldLoad = ({ filterProps, limit, start, sortBy }, gamesCollection) => {
    const results =
        gamesCollection[
            getKeyForGamesInCategory({
                filterProps,
                start: start ?? 0,
                limit,
                sortBy,
            })
        ];
    if (getObjectField(results, 'isLoading', false) === true) {
        return false;
    }
    return !getObjectField(results, 'data');
};
export const getCasinoCollectionIsLoading = ({ filterProps, limit, start, sortBy }, gamesCollection) => {
    const results =
        gamesCollection[
            getKeyForGamesInCategory({
                filterProps,
                start: start ?? 0,
                limit,
                sortBy,
            })
        ];
    return getObjectField(results, 'isLoading', false);
};
export const getCasinoCollectionLoadingError = ({ filterProps, limit, start, sortBy }, gamesCollection) => {
    const results =
        gamesCollection[
            getKeyForGamesInCategory({
                filterProps,
                start: start ?? 0,
                limit,
                sortBy,
            })
        ];
    return !!getObjectField(results, 'error');
};
export const getCasinoCollectionGamesCount = ({ filterProps, limit, start, sortBy }, gamesCollection) => {
    const results =
        gamesCollection[
            getKeyForGamesInCategory({
                filterProps,
                start: start ?? 0,
                limit,
                sortBy,
            })
        ];
    return getObjectField(results, 'meta.pagination.total', 0);
};
export const getCasinoCollectionGames = ({ filterProps, limit, start, sortBy }, gamesCollection) => {
    const results =
        gamesCollection[
            getKeyForGamesInCategory({
                filterProps,
                start: start ?? 0,
                limit,
                sortBy,
            })
        ];
    return getObjectField(results, 'data', []);
};

export const getImagesForCasinoGame = (game) => {
    const imageSizesValue = casinoImageSizesValue;
    const maxNumberOfImages = imageSizesValue.length;
    const imagesArr = Object.values(getObjectField(game.squareImage, 'data.attributes.formats', {}));

    const mainImage = getObjectField(game.squareImage, 'data.attributes');
    if (mainImage) {
        imagesArr.push({
            width: mainImage.width,
            url: mainImage.url,
        });
    }
    const providerThumbnails = game.providerThumbnails || [];
    const gameThumbsSet = imagesArr.length > 0 ? imagesArr : providerThumbnails.slice(0, maxNumberOfImages);

    return gameThumbsSet
        .map((image, index) => ({
            url: image.url || image,
            width: imagesArr.length ? image.width : imageSizesValue[index],
        }))
        .sort((a, b) => a.width - b.width);
};
